import React from 'react';

// 今後、削除予定の画面のため、styleや出力メッセージはすべて本ファイルに記載
// 削除する際は、本ファイルの削除および呼出し元（Loginページ）を修正する
const Guide = () => {
    const guideStyle = {
        fontSize: '24px',
        paddingTop: '30px'
    };

    return (
        <div style={guideStyle}>
            <p>QRコードから<br/>読み込んでください。</p>
        </div>
    );
};
export default Guide;