import Amplify from '@aws-amplify/core';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import {serverConstants} from './env/settings';
import {sentryConfig} from './resources/appConstants';

const store = configureStore();
/**
 * Amplifyの設定適用
 */
Amplify.configure(serverConstants.AWS_CONFIG);

// Sentry設定（結合テスト環境のみ）
if (process.env.REACT_APP_STAGE.trim() === 'dev') {
    (async () => {
        const Sentry = await import('@sentry/react');
        const {Integrations} = await import('@sentry/tracing');
        Sentry.init({
            dsn: sentryConfig.DSN,
            integrations: [
                new Integrations.BrowserTracing(),
            ],
            tracesSampleRate: 1,
        });
    })();
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Route path="*/" component={App}/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
