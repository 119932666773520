import {applyMiddleware, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {rootReducer} from '../reducers';
import rootSaga from '../sagas';

// ロガー設定
// 変更の出力とアクション詳細の折り畳み表示

const configureStore = (initialState) => {
    const sagaMiddleware = createSagaMiddleware();
    const logger = createLogger({
        diff: true,
        collapsed: true,
    });
    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(
            sagaMiddleware,
            logger
        )
    );

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureStore;