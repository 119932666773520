import {call} from 'redux-saga/effects';
import {apiRequestPost} from '../modules/apiRequest';
import consoleMethod from '../modules/consoleMethod';
import {registrationPlaceToStayAPI} from '../resources/apiConstants';
import {errorHandling} from '../resources/appConstants';
import {messageConstants} from '../resources/messages';


/**
 * 情報登録
 * @param action
 * @returns {IterableIterator<CallEffect>}
 */
export function* registrationPlaceToStay(action) {
    registrationPlaceToStayAPI.init.body = action.payload.param;
    const {payload, err} = yield call(apiRequestPost, registrationPlaceToStayAPI);
    if (!payload) {
        consoleMethod(err, 'error');
        //ステータスコードが401 or 403の場合、アラート表示、ログイン画面に遷移
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
            alert(messageConstants.EF9999 + messageConstants.EF0005);
            action.payload.callback(errorHandling.TOKEN_ERROR);
        } else if (err && err.response) {
            //APIのエラー発生時、アラート表示、画面遷移なし
            alert(err.response.data.message);
            action.payload.callback(errorHandling.API_ERROR);
        } else {
            // その他のエラーの場合、アラート表示を表示、画面遷移なし
            alert(messageConstants.EF9999);
        }
    } else {
        alert(payload.data.message);
        consoleMethod(payload, 'info');
        //登録したデータで更新
        action.payload.callback(errorHandling.SUCCESS);
    }
}