import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {messageConstants} from '../../resources/messages';
import {updatePlaceToStayApi} from '../../actions/apiActions';
import {formatDateTime, formatRequestDate} from '../../modules/formatMessage';
import {commonStrings, errorHandling, expectedTime, localStorageKey, pathName} from '../../resources/appConstants';
import styles from '../../styles/style.module.scss';


/**
 * 情報登録画面
 * @param props
 * @returns {*}
 * @constructor
 */
const Registration = props => {
    const history = useHistory();
    const [enteredAt, setEnteredAt] = useState(0);
    const [updateDate, setUpdateDate] = useState(0);
    const [restoredData, setRestoredData] = useState({});

    // 画面読み込み時に初期処理を実施
    useEffect(() => initProcess(), []);

    // 初期処理
    const initProcess = () => {
        //ローカルストレージからアクセス時刻を取得しフォーマット化
        setEnteredAt(Date.parse(localStorage.getItem(localStorageKey.DATE_TIME)));
        setUpdateDate(Date.parse(localStorage.getItem(localStorageKey.DATE_TIME)));

        const tempData = JSON.parse(localStorage.getItem(localStorageKey.TEMP_DATA));
        if (!tempData) {
            history.push(pathName.registeredHistory);
        } else {
            setRestoredData({
                globalId: tempData.tempName,
                locationId: tempData.locationId,
                enteredAt: tempData.enteredAt,
                exitedAt: tempData.exitedAt
            });
        }
    };


    // 滞在時間ボタン押下
    const handleExpectedTime = item => {
        const newExitedAt = new Date(updateDate);
        const addMinute = parseInt(item.value, 10);
        const exitedAt = newExitedAt.setMinutes(newExitedAt.getMinutes() + addMinute);
        props.updatePlaceToStay({
            exitedAt: formatRequestDate(exitedAt),
            enteredAt: formatRequestDate(enteredAt)
        }, (flag) => {
            if(flag){
                switch (flag) {
                    case errorHandling.SUCCESS:
                        //登録内容でローカルストレージを更新
                        localStorage.setItem(localStorageKey.TEMP_DATA,
                            JSON.stringify(
                                {
                                    //ローカルストレージの認証情報からglobalIdを取得
                                    tempName: restoredData.globalId,
                                    locationId: restoredData.locationId,
                                    enteredAt: enteredAt,
                                    exitedAt: exitedAt,
                                }));
                        //退出画面遷移
                        history.push(pathName.exit);
                        break;
                    case errorHandling.TOKEN_ERROR:
                        history.push(pathName.login);
                        break;
                    default:
                        break;
                }
            } else {
                alert(messageConstants.EF9999);
            }

        });
    };

    //履歴ボタン押下
    const historyButton = () => {
        history.push(pathName.registeredHistory);
    };

    return (
        <div className={styles.registration_wrap}>
            <div className={styles.registration_item_wrap}>
                <div className={styles.registration_item}>{commonStrings.NICK_NAME}</div>
                <div className={styles.registration_data}>{localStorage.getItem(localStorageKey.AUTHORIZED_OPTION)}</div>
                <div className={styles.registration_item}>{commonStrings.PLACE}</div>
                <div className={styles.registration_data}>{restoredData.locationId}</div>
                <div className={styles.registration_item}>{commonStrings.START_DATETIME}</div>
                <div className={styles.registration_data}>{formatDateTime(restoredData.enteredAt)}</div>
                <div className={styles.registration_item}>{commonStrings.END_DATETIME}</div>
                <div className={styles.registration_data}>{formatDateTime(restoredData.exitedAt)}</div>
            </div>
            <div className={styles.registration_line}/>
            <div className={styles.registration_time_wrap}>
                <div className={styles.registration_item}>{commonStrings.STAY_SCHEDULED}</div>
                <div className={styles.registration_button_wrap}>
                    {/*滞在予定時間ボタン*/}
                    {expectedTime.map(item =>
                        <div key={item.value} className={styles.registration_button_wrap2}>
                            <button id={'addMinute'} key={item.value} value={item.value} type={'button'}
                                    className={styles.registration_time_button}
                                    onClick={() => handleExpectedTime(item)}>{item.text}</button>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.registration_line}/>
            <div className={styles.registration_header}>
                <button className={styles.registration_wide_button} type={'button'}
                        onClick={historyButton}>{commonStrings.HISTORY}</button>
            </div>
        </div>
    );
};

Registration.propTypes = {
    globalId: PropTypes.string,
    locationId: PropTypes.string,
    dateTime: PropTypes.string,
    enteredAt: PropTypes.string,
    exitedAt: PropTypes.string,
    updatePlaceToStay: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        updatePlaceToStay: (param, callback) => dispatch(updatePlaceToStayApi({param: param, callback: callback}))
    };
};

/**
 * connect
 */
export default connect(
    '',
    mapDispatchToProps,
)(Registration);