import {call, put} from 'redux-saga/effects';
import {updateSelectedRegisteredHistory} from '../actions/RegisteredHistory';
import {apiRequestPut} from '../modules/apiRequest';
import consoleMethod from '../modules/consoleMethod';
import {updateRegisteredHistoryAPI} from '../resources/apiConstants';
import {errorHandling} from '../resources/appConstants';
import {messageConstants} from '../resources/messages';


/**
 * 履歴情報更新APISaga
 * @param action
 * @returns {IterableIterator<CallEffect|PutEffect<{type}>>}
 */
export function* updateRegisteredHistory(action) {
    let isSuccess = false;

    updateRegisteredHistoryAPI.init.body = action.payload.item;

    const {payload, err} = yield call(apiRequestPut, updateRegisteredHistoryAPI);
    if (!payload) {
        consoleMethod(err, 'error');
        //ステータスコードが401 or 403の場合、アラート表示、ログイン画面に遷移
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
            alert(messageConstants.EF9999 + messageConstants.EF0005);
            action.payload.callback(errorHandling.TOKEN_ERROR);
        } else if (err && err.response) {
            //APIのエラー発生時、アラート表示、画面遷移なし
            alert(err.response.data.message);
            action.payload.callback(errorHandling.API_ERROR);
        } else {
            // その他のエラーの場合、アラート表示を表示、画面遷移なし
            alert(messageConstants.EF9999);
        }
    } else {
        consoleMethod(payload, 'info');
        //成功用アクション;
        alert(payload.data.message);
        yield put(updateSelectedRegisteredHistory(action.payload.item));
        action.payload.callback(errorHandling.SUCCESS);
    }
}
