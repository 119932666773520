import API from '@aws-amplify/api';
import consoleMethod from './consoleMethod';


const apiRequestGet = (req) => {
    consoleMethod(req, 'log');
    //パスパラメータが必要な場合はapiPathに足す
    const apiPath = req.path;
    return API.get(req.apiName, apiPath, req.init)
        .then((payload) => {
            //statusが200なら、payloadを返す
            if (payload.status === 200) {
                return payload;
            }
            //statusが200以外なら、payloadをthrowしてerrとしてcatchする
            throw payload;
        })
        .then(payload => ({payload}))
        .catch(err => ({err}));
};


const apiRequestPost = (req) => {
    consoleMethod(req, 'log');
    //パスパラメータが必要な場合はapiPathに足す
    const apiPath = req.path;
    return API.post(req.apiName, apiPath, req.init)
        .then((payload) => {
            if (payload.status === 200) {
                return payload;
            }
            throw payload;
        })
        .then(payload => ({payload}))
        .catch(err => ({err}));
};


const apiRequestPut = (req) => {
    consoleMethod(req, 'log');
    //パスパラメータが必要な場合はapiPathに足す
    const apiPath = req.path;
    return API.put(req.apiName, apiPath, req.init)
        .then((payload) => {
            if (payload.status === 200) {
                return payload;
            }
            throw payload;
        })
        .then(payload => ({payload}))
        .catch(err => ({err}));
};

export {apiRequestGet, apiRequestPost, apiRequestPut};
