import {createAction} from 'redux-actions';


/**
 * API ACTIONS
 * @type {{}}
 */
export const API_ACTIONS = {
    REGISTRATION_PLACE_TO_STAY: 'REGISTRATION_PLACE_TO_STAY',
    UPDATE_PLACE_TO_STAY: 'UPDATE_PLACE_TO_STAY',
    SIGN_UP: 'SIGN_UP',
    UPDATE_REGISTERED_HISTORY_API: 'UPDATE_REGISTERED_HISTORY_API',
    GET_REGISTERED_HISTORY_API: 'GET_REGISTERED_HISTORY_API',
};

//滞在情報登録
export const registrationPlaceToStayApi = createAction(API_ACTIONS.REGISTRATION_PLACE_TO_STAY);

//滞在情報更新
export const updatePlaceToStayApi = createAction(API_ACTIONS.UPDATE_PLACE_TO_STAY);

//新規登録
export const signUpApi = createAction(API_ACTIONS.SIGN_UP);

//履歴一覧API
export const getRegisteredHistoryAPI = createAction(API_ACTIONS.GET_REGISTERED_HISTORY_API);

//履歴更新API
export const updateRegisteredHistoryAPI = createAction(API_ACTIONS.UPDATE_REGISTERED_HISTORY_API);
