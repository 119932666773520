import {combineReducers} from 'redux';
import place from './place';
import registeredHistory from './registeredHistory';

/**
 * rootReducer
 * @type {Reducer<CombinedState<any>>}
 */
export const rootReducer = combineReducers({
    place,
    registeredHistory
});
