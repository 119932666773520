// TODO メッセージ暫定（安藤）
/**
 * メッセージ
 * @type {{EF0001: string}}
 */
export const messageConstants = {
    IF0001: '登録しました。',
    EF0001: 'GlobalIdまたはパスワードに誤りがあります。',
    EF0002: 'エラーが発生しました。',
    EF0003: '通信に失敗しました。通信状況を確認してください。',
    EF0004: '開始時間より前は登録できません。',
    EF0005: '再度ログインしてください。',
    EF0006: 'ニックネームまたはパスワードに誤りがあります。',
    EF9999: 'エラーが発生しました。',
};

/**
 * cognitoのエラーコードをキーにメッセージ文言をマッピング
 * @type {{UserNotFoundException: string, NotAuthorizedException: string}}
 */
export const cognitoException = {
    // ユーザIDに誤りがある場合に使用
    UserNotFoundException: messageConstants.EF0006,
    // パスワードに誤りがある場合に使用
    NotAuthorizedException: messageConstants.EF0006,
    //通信エラーが発生した場合に使用
    NetworkError: messageConstants.EF0003
};
