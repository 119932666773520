import {PLACE_ACTIONS} from '../actions/place';


/**
 * 初期値
 * @type {{locationId: string}}
 */
const initialState = {
    locationId: '',
    enteredAt: null,
    exitedAt: null
};

/**
 * place
 * @param state
 * @param action
 * @returns {string|{password: string, nickname: string, avatar_name: string, mail_address: string, current_point: number, current_taggy: number}}
 */
export default function place(state = initialState, action) {
    if (action.type === PLACE_ACTIONS.PASS_PLACE_CODE) {
        state = {
            //中身を展開
            ...state,
            locationId: action.payload.locationId,
            enteredAt: action.payload.enteredAt,
            exitedAt: action.payload.exitedAt
        };
        return state;
    } else {
        return state;
    }
}
