import {dateFormat, dateTimeFormat, registrationDateTimeFormat, timeFormat} from '../resources/appConstants';

/**
 * テンプレートメッセージに対して、文字列を埋め込む
 * @param {String} msg ベースとなる文字列
 * @param {Array<*>} args はめ込みたい値の配列
 * @returns {String} 成形した文字列
 */
export const formatMessage = (msg, args) => {
    let formedMsg = msg;
    args.forEach((item, idx) => formedMsg = formedMsg.replace(`{${idx}}`, item));
    return formedMsg;
};

/**
 * APIから取得した日時を詳細画面用日時フォーマット
 * @param datetime
 * @returns {String}
 */
export const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = date.getDay();
    const dayOfWeekStr = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'][dayOfWeek];
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    const second = ('0' + date.getSeconds()).slice(-2);
    return formatMessage(dateTimeFormat.format, [year, month, day, dayOfWeekStr, hour, minute, second]);
};

/**
 * APIから取得した日時を履歴日付フォーマット
 * @param datetime
 * @returns {String}
 */
export const formatDate = (datetime) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const dayOfWeek = date.getDay();
    const dayOfWeekStr = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'][dayOfWeek];
    return formatMessage(dateFormat.format, [year, month, day, dayOfWeekStr]);
};


/**
 * APIから取得した日時を時刻フォーマット
 * @param datetime
 * @returns {String}
 */
export const formatTime = (datetime) => {
    const date = new Date(datetime);
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    const second = ('0' + date.getSeconds()).slice(-2);
    return formatMessage(timeFormat.format, [hour, minute, second]);
};

/**
 * 日時をフォーマット
 * @param datetime
 * @returns {String}
 */
export const formatRequestDate = (datetime) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    const second = ('0' + date.getSeconds()).slice(-2);
    return formatMessage(registrationDateTimeFormat.format, [year, month, day, hour, minute, second]);
};

