import Auth from '@aws-amplify/auth';
import {call} from 'redux-saga/effects';
import {apiRequestPost} from '../modules/apiRequest';
import consoleMethod from '../modules/consoleMethod';
import {signUpAPI} from '../resources/apiConstants';
import {cognitoException, messageConstants} from '../resources/messages';

/**
 * 新規登録
 * @param action
 * @returns {IterableIterator<Promise<{res:  | any} | {error: any}>|CallEffect>}
 */
export function* signUp(action) {
    signUpAPI.init.body = {
        name: action.payload.param.name,
        password: action.payload.param.password,
        username: action.payload.param.userName
    };
    const {payload, err} = yield call(apiRequestPost, signUpAPI);
    if (!payload) {
        //新規登録に失敗した場合
        consoleMethod(err, 'error');
        if (err) {
            if (err.response) {
                //APIエラー
                alert(err.response.data.message);
            } else {
                //その他エラー
                alert(messageConstants.EF0002);
            }
        }
    } else {
        //成功
        consoleMethod(payload, 'info');
        //「登録しました」
        alert(payload.data.message);
        // 登録したアカウントでログイン
        const {res, error} = yield Auth.signIn(action.payload.param.userName, action.payload.param.password)
            .then((res) => {
                return {res};
            })
            .catch((error) => {
                return {error};
            });

        if (res) {
            action.payload.callback(true);
        } else {
            //ログインに失敗した場合
            consoleMethod(error, 'error');
            alert(cognitoException[error.code]);
        }

    }
}
