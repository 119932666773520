import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Route, useHistory} from 'react-router-dom';
import Exit from './components/page/Exit';
import Guide from './components/page/Guide';
import Login from './components/page/Login';
import RegisteredDisplay from './components/page/RegisteredDisplay';
import RegisteredHistory from './components/page/RegisteredHistory';
import RegisteredHistoryDetail from './components/page/RegisteredHistoryDetail';
import Registration from './components/page/Registration';
import SignUp from './components/page/SignUp';
import WaitRegistration from './components/page/WaitRegistration';
import {formatRequestDate} from './modules/formatMessage';
import {localStorageKey, pathName} from './resources/appConstants';
import './styles/App.css';
import styles from './styles/style.module.scss';
// TODO 相対パス修正

/**
 * URLから場所コードを取得
 * @param param
 * @returns {string|null}
 */
const getPlace = (param) => {
    // eslint-disable-next-line no-useless-escape
    const parameter = param.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + parameter + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(window.location.href);
    // クエリパラメータがない場合nullを返却
    if (!results) return null;
    // クエリパラメータの値がない場合空文字を返却
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * App
 * @returns {*}
 * @constructor
 */
const App = () => {
    const history = useHistory();

    //初回レンダリング時のみ
    useEffect(() => {
        //現在時刻取得
        const enteredAt = new Date();
        const exitedAt = new Date();

        exitedAt.setMinutes(exitedAt.getMinutes() + 15);

        //TODO ドメイン判断(安藤)
        const locationId = getPlace('place');

        //場所コードをローカルストレージに保存
        if (locationId) {
            localStorage.setItem(localStorageKey.LOCATION_ID, locationId);
        }
        //TODO ストア管理（安藤）
        localStorage.setItem(localStorageKey.ENTERED_AT, formatRequestDate(enteredAt));
        localStorage.setItem(localStorageKey.EXITED_AT, exitedAt.toString());
        localStorage.setItem(localStorageKey.DATE_TIME, enteredAt.toString());
        const autoLogin = async () => {
            //セッション確認
            const {res, err} = await Auth.currentSession()
                .then((res) => {
                    return {res};
                })
                .catch((err) => {
                    return {err};
                });
            if (res && (history.location.pathname === '' || history.location.pathname === pathName.slash)) {
                history.push(pathName.loading);
            } else if (err) {
                history.push(pathName.login);
            }
        };
        autoLogin();
    }, []);

    return (
        <div className={styles.app}>
            <Route path={pathName.login} component={Login}/>
            <Route path={pathName.exit} component={Exit}/>
            <Route path={pathName.loading} component={WaitRegistration}/>
            <Route path={pathName.guide} component={Guide}/>
            <Route path={pathName.signUp} component={SignUp}/>
            <Route path={pathName.registration} component={Registration}/>
            <Route path={pathName.registeredDisplay} component={RegisteredDisplay}/>
            <Route path={pathName.registeredHistory} component={RegisteredHistory}/>
            <Route path={pathName.registeredHistoryDetail} component={RegisteredHistoryDetail}/>
        </div>
    );
};

App.propTypes = {
    enteredAt: PropTypes.instanceOf(Date),
    exitedAt: PropTypes.instanceOf(Date)
};

export default App;
