import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {updatePlaceToStayApi} from '../../actions/apiActions';
import {formatDateTime, formatRequestDate} from '../../modules/formatMessage';
import {commonStrings, errorHandling, exitStrings, localStorageKey, pathName} from '../../resources/appConstants';
import styles from '../../styles/style.module.scss';


/**
 * 退出画面
 * @param props
 * @returns {*}
 * @constructor
 */
const Exit = props => {
    const history = useHistory();
    const [enteredAt, setEnteredAt] = useState(null);
    const [restoredData, setRestoredData] = useState({});

    //ローカルストレージの認証情報からglobalIdを取得
    const globalId = localStorage.getItem(localStorageKey.AUTHORIZED_OPTION);
    //TODO 今回は履歴ボタンをコメントアウト
    //履歴ボタン押下
    const historyButton = () => {
        // 履歴一覧画面に遷移
        history.push(pathName.registeredHistory);
    };

    //今すぐ退出ボタン押下
    const handleExit = () => {
        //ボタン押下時の時間取得
        const exitTime = new Date();
        props.updatePlaceToStay({
            enteredAt: formatRequestDate(enteredAt),
            exitedAt: formatRequestDate(exitTime)
        }, (flag) => {
            switch (flag) {
                case errorHandling.SUCCESS:
                //登録内容でローカルストレージを更新
                    localStorage.setItem(localStorageKey.TEMP_DATA,
                        JSON.stringify(
                            {
                                //ローカルストレージの認証情報からglobalIdを取得
                                tempName: globalId,
                                locationId: restoredData.locationId,
                                enteredAt: restoredData.enteredAt,
                                exitedAt: exitTime,
                            }));
                    //登録内容表示に遷移
                    history.push(pathName.registeredDisplay);
                    break;
                case errorHandling.TOKEN_ERROR:
                    history.push(pathName.login);
                    break;
                default:
                    break;
            }
        });
    };

    // 初期処理
    const initProcess = () => {
        //ローカルストレージからアクセス時刻を取得しフォーマット化
        setEnteredAt(Date.parse(localStorage.getItem(localStorageKey.DATE_TIME)));

        const tempData = JSON.parse(localStorage.getItem(localStorageKey.TEMP_DATA));
        if (!tempData) {
            history.push(pathName.registeredHistory);
        } else {
            setRestoredData({
                globalId: tempData.tempName,
                locationId: tempData.locationId,
                enteredAt: tempData.enteredAt,
                exitedAt: tempData.exitedAt
            });
        }
    };

    // 画面読み込み時に初期処理を実施
    useEffect(() => initProcess(), []);

    return (
        <div className={styles.registration_wrap}>
            <div className={styles.registration_item_wrap}>
                <div className={styles.registration_item}>{commonStrings.NICK_NAME}</div>
                <div
                    className={styles.registration_data}>{localStorage.getItem(localStorageKey.AUTHORIZED_OPTION)}</div>
                <div className={styles.registration_item}>{commonStrings.PLACE}</div>
                <div className={styles.registration_data}>{restoredData.locationId}</div>
                <div className={styles.registration_item}>{commonStrings.START_DATETIME}</div>
                <div className={styles.registration_data}>{formatDateTime(restoredData.enteredAt)}</div>
                <div className={styles.registration_item}>{commonStrings.END_DATETIME}</div>
                <div className={styles.registration_data}>{formatDateTime(restoredData.exitedAt)}</div>
            </div>
            <div className={styles.registration_line}/>
            <div className={styles.registration_header}>
                <button className={styles.registration_wide_button} type={'button'}
                        onClick={handleExit}
                >{exitStrings.EXIT_NOW}</button>
            </div>
            <div className={styles.registration_header}>
                <button className={styles.registration_wide_button} type={'button'}
                        onClick={historyButton}
                >{commonStrings.HISTORY}</button>
            </div>
        </div>
    );
};

Exit.propTypes = {
    // 関数
    updatePlaceToStay: PropTypes.func.isRequired
};

/**
 * mapDispatchToProps
 * @param dispatch
 * @returns {{updatePlaceToStay: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => {
    return {
        updatePlaceToStay: (param, callback) => dispatch(updatePlaceToStayApi({param: param, callback: callback})),
    };
};

/**
 * connect
 */
export default connect(
    '',
    mapDispatchToProps,
)(Exit);
