import {call, put} from 'redux-saga/effects';
import {updateRegisteredHistoryList} from '../actions/RegisteredHistory';
import {apiRequestPost} from '../modules/apiRequest';
import consoleMethod from '../modules/consoleMethod';
import {getRegisteredHistoryAPI} from '../resources/apiConstants';
import {messageConstants} from '../resources/messages';
import {formatMessage} from '../modules/formatMessage';
import {errorHandling, requestDatetimeFormat} from '../resources/appConstants';


/**
 * 履歴一覧表示APISaga
 * @param action
 * @returns {IterableIterator<CallEffect|PutEffect<{type}>>}
 */
export function* getRegisteredHistory(action) {
    // パラメーター作成
    // todo 各パラメータ設定は固定(日時は今から1日前、ソートは降順) 変更の可能性大のため影響を考え設定値化しない
    const now = new Date();
    const enteredAtTo = getEnteredAtToString(now);
    now.setDate(now.getDate() - 1);
    const enteredAtFrom = getEnteredAtFromString(now);

    getRegisteredHistoryAPI.init.body = {
        enteredAtFrom: enteredAtFrom,
        enteredAtTo: enteredAtTo,
        isAscSort: 'False'
    };

    const {payload, err} = yield call(apiRequestPost, getRegisteredHistoryAPI);
    if (!payload) {
        consoleMethod(err, 'error');
        //ステータスコードが401 or 403の場合、アラート表示、ログイン画面に遷移
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
            alert(messageConstants.EF9999 + messageConstants.EF0005);
            action.payload.callback(errorHandling.TOKEN_ERROR);
        } else if (err.response && err.response.data && err.response.data.message) {
            //APIのエラー発生時、アラート表示、画面遷移なし
            alert(err.response.data.message);
        } else {
            // その他のエラーの場合、アラート表示を表示、画面遷移なし
            alert(messageConstants.EF9999);
        }
    } else {
        consoleMethod(payload, 'info');
        //成功用アクション
        yield put(updateRegisteredHistoryList(payload.data));
    }
}

/**
 * 終了日付の文字列化
 * @param dt
 * @returns {string}
 */
const getEnteredAtToString = (dt) => {
    return formatMessage(requestDatetimeFormat, [dt.getFullYear(), padZero(dt.getMonth() + 1), padZero(dt.getDate()), padZero(dt.getHours()), padZero(dt.getMinutes()), padZero(dt.getSeconds())]);
};

/**
 * 開始日付の文字列化
 * @param dt
 * @returns {string}
 */
const getEnteredAtFromString = (dt) => {
    // 開始日時の時間は00:00:00固定
    return formatMessage(requestDatetimeFormat, [dt.getFullYear(), padZero(dt.getMonth() + 1), padZero(dt.getDate()), '00', '00', '00']);
};

/**
 * 先頭ゼロ付加
 * @param num
 * @returns {*}
 */
const padZero = (num) => {
    return (num < 10 ? '0' : '') + num;
};

