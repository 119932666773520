import {Auth} from '@aws-amplify/auth';
import SHA256 from 'crypto-js/sha256';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {localStorageKey, loginStrings, pathName, systemName} from '../../resources/appConstants';
import {cognitoException} from '../../resources/messages';
import styles from '../../styles/style.module.scss';
// TODO 相対パス修正


/**
 * ログイン
 * @returns {*}
 * @constructor
 */
const Login = () => {
    const history = useHistory();
    //Stateの設定
    const [nickName, setNickName] = useState('');
    const [password, setPassword] = useState('');

    const getId = async () => await Auth.currentAuthenticatedUser().then(res => {
        localStorage.setItem('authorized-option', res.getUsername());
    });

    const locationId = localStorage.getItem(localStorageKey.LOCATION_ID);

    // ログイン押下時
    const handleClickLogin = async () => {
        const form = document.getElementById('form');
        const isValid = form.reportValidity();
        if (isValid) {
            //パスワードをハッシュ化
            const shaPassword = SHA256(loginStrings.FIXED_STRING + password).toString();
            const {res, err} = await Auth.signIn(nickName, shaPassword)
                .then((res) => {
                    return {res};
                })
                .catch((err) => {
                    return {err};
                });

            if (res) {
                // 成功
                await getId();
                //滞在情報登録画面に遷移
                history.push(locationId ? pathName.loading : pathName.registeredHistory);
                //TODO 今回はガイド画面を表示
                //history.push(locationId?pathName.registration:pathName.guide);
            } else {
                // 失敗
                alert(cognitoException[err.code]);
            }
        }
    };

    // 新規登録押下時
    const handleSignUp = () => {
        history.push(pathName.signUp);
    };

    return (
        <div className={styles.login_wrap}>
            <div className={styles.title}>{systemName}</div>
            <div className={styles.login_body}>
                <form name="user" action="#" id="form" className={styles.login_form_wrap}>
                    <div className={styles.login_form}>
                        {/*ニックネーム*/}
                        <div>{loginStrings.NICK_NAME}</div>
                        <input placeholder={loginStrings.NICK_NAME} type='text' id='nickName' name='nickName'
                               className={styles.login_form_input} required='required'
                               value={nickName} onChange={(event) => {
                            setNickName(event.target.value);
                        }}/>
                    </div>
                    <div className={styles.login_form}>
                        <div>{loginStrings.PASSWORD}</div>
                        {/*アカウント情報保存確認ダイアログ・パスワード漏洩ダイアログ回避のためtype=textに設定*/}
                        <input placeholder={loginStrings.PASSWORD} /*type="password"*/ id="password" name="password"
                               className={styles.login_form_input_password} autoComplete="OFF"
                               required='required' type="text"
                               value={password} onChange={(event) => {
                            setPassword(event.target.value);
                        }}/>
                    </div>
                </form>

                <div className={styles.login_buttons_wrap}>
                    {/*ログインボタン*/}
                    <button type={'button'} className={styles.login_button}
                            onClick={handleClickLogin}>{loginStrings.LOGIN}</button>
                    <div/>
                    {/*新規登録ボタン*/}
                    <button type={'button'} className={styles.login_button} onClick={handleSignUp}
                    >
                        {loginStrings.CREATE_ACCOUNT}
                    </button>
                </div>
            </div>
        </div>
    );
};


export default Login;
