import {Auth} from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {registrationPlaceToStayApi} from '../../actions/apiActions';
import {formatRequestDate} from '../../modules/formatMessage';
import {errorHandling, localStorageKey, pathName} from '../../resources/appConstants';
import loading from '../../resources/icon_loader_a_bb_01_s1.gif';
import styles from '../../styles/style.module.scss';


/**
 * 情報登録loading画面
 * @param props
 * @returns {*}
 * @constructor
 */
const WaitRegistration = (props) => {
    const history = useHistory();

    useEffect(() => {
        const enteredAt = Date.parse(localStorage.getItem(localStorageKey.ENTERED_AT));
        const exitedAt = Date.parse(localStorage.getItem(localStorageKey.EXITED_AT));
        const locationId = localStorage.getItem(localStorageKey.LOCATION_ID);
        const getId = async () => await Auth.currentAuthenticatedUser().then(res => {
            localStorage.setItem('authorized-option', res.getUsername());
        });
        const checkSession = async () => {
            await getId();
            if (locationId) {
                //登録処理
                if (localStorage.getItem(localStorageKey.AUTHORIZED_OPTION)) {
                    //トークンが有効期限内の場合は登録画面遷移、ない場合はログイン画面遷移
                    props.registrationPlaceToStay({
                        locationId: locationId,
                        enteredAt: formatRequestDate(enteredAt),
                        //15分後の時間を設定
                        exitedAt: formatRequestDate(exitedAt)
                    }, (flag) => {
                        //ローカルストレージの場所コードを削除
                        localStorage.removeItem(localStorageKey.LOCATION_ID);
                        switch (flag) {
                            case errorHandling.SUCCESS:
                                //登録内容をローカルストレージに保存
                                localStorage.setItem(localStorageKey.TEMP_DATA,
                                    JSON.stringify({
                                        //ローカルストレージの認証情報からglobalIdを取得
                                        tempName: localStorage.getItem(localStorageKey.AUTHORIZED_OPTION),
                                        locationId: locationId,
                                        enteredAt: formatRequestDate(enteredAt),
                                        exitedAt: formatRequestDate(exitedAt),
                                    }));
                                //滞在情報登録に遷移
                                history.replace(pathName.registration);
                                break;
                            case errorHandling.TOKEN_ERROR:
                                history.push(pathName.login);
                                break;
                            default:
                                break;
                        }
                    });
                } else {
                    //ローカルストレージの場所コードを削除
                    localStorage.removeItem(localStorageKey.LOCATION_ID);
                    history.replace(pathName.login);
                }
            } else {
                //履歴画面に遷移
                history.replace(pathName.registeredHistory);
                //TODO 今回は使い方ガイドに遷移
                //history.replace(athName.guide);
            }
        };
        checkSession();
    }, []);

    return (
        <div>
            <img className={styles.loading} src={loading} alt="loading"/>
        </div>
    );
};

WaitRegistration.propTypes = {
    globalId: PropTypes.string,
    locationId: PropTypes.string,
    dateTime: PropTypes.instanceOf(Date),
    registrationPlaceToStay: PropTypes.func.isRequired,
    enteredAt: PropTypes.instanceOf(Date),
    exitedAt: PropTypes.string,

};

const mapStateToProps = state => {
    return {
        locationId: state.place.locationId,
        dateTime: state.place.dateTime,
        exitedAt: state.place.exitedAt
    };
};

const mapDispatchToProps = dispatch => {
    return {
        registrationPlaceToStay: (param, callback) => dispatch(registrationPlaceToStayApi({
            param: param,
            callback: callback
        })),
    };
};

/**
 * connect
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WaitRegistration);