/**
 * システム名
 * @type {string}
 */
export const systemName = 'システム名';


/**
 * パス名
 * @type {{registeredHistoryDetail: string, registeredContent: string, registration: string, registeredHistory: string, login: string, signUp: string, registeredDisplay: string, content: string, guide: string}}
 */
export const pathName = {
    slash: '/',
    login: '/login',
    registration: '/registration',
    signUp: '/signUp',
    exit: '/exit',
    registeredHistory: '/registeredHistory',
    registeredHistoryDetail: '/registeredHistoryDetail',
    guide: '/guide',
    loading: '/loading',
    content: '/content',
    registeredDisplay: '/registeredDisplay'
};

/***
 * 共通項目画面
 * @type {{GLOBAL_ID: String}}
 */
export const commonStrings = {
    NICK_NAME: 'ニックネーム：',
    PLACE: '場所：',
    START_DATETIME: '開始時間：',
    END_DATETIME: '終了時間：',
    HISTORY: '履歴',
    STAY_SCHEDULED: '滞在予定時間：',
};


/**
 * 履歴日付フォーマット
 * @type {{format: string}}
 */
export const dateFormat = {
    format: '{0}/{1}/{2} {3}'
};

/**
 * 履歴日時フォーマット
 * @type {{format: string}}
 */
export const dateTimeFormat = {
    format: '{0}年{1}月{2}日{3} {4}:{5}:{6}'
};

/**
 * APIリクエスト用日時フォーマット
 * @type {string}
 */
export const requestDatetimeFormat = '{0}/{1}/{2} {3}:{4}:{5}';

/**
 * 履歴時間フォーマット
 * @type {{format: string}}
 */
export const timeFormat = {
    format: '{0}:{1}:{2}'
};

/***
 * 履歴一覧画面
 * @type {{BACK: String}}
 */
export const historyStrings = {
    BACK: '戻る',
    SAVE: '保存',
    TIME_PLACE_FORMAT: '{0} ~ {1} {2}'
};

/***
 * ログイン画面
 * @type {{PASSWORD: string, CREATE_ACCOUNT: string, LOGIN: string, ID: string, GLOBAL_ID: string}}
 */
export const loginStrings = {
    GLOBAL_ID: 'GlobalId',
    NICK_NAME: 'ニックネーム',
    CREATE_ACCOUNT: '新規登録',
    LOGIN: 'ログイン',
    PASSWORD: 'Password',
    ID: 'ID',
    //パスワード用固定文字列(ランダムに生成)
    FIXED_STRING: 'OYr5BNRNuX'
};

/**
 * 滞在予定時間ボタン用
 * @type {*[]}
 */
export const expectedTime = [
    {value: 30, text: '30分'},
    {value: 45, text: '45分'},
    {value: 60, text: '60分以上'}
];


/**
 * 登録日時フォーマット
 * @type {{format: string}}
 */
export const registrationDateTimeFormat = {
    format: '{0}/{1}/{2} {3}:{4}:{5}'
};

/**
 * 退出画面用
 * @type {{EXIT_NOW: string}}
 */
export const exitStrings = {
    EXIT_NOW: '今すぐ退出'
};

/**
 * ローカルストレージkey
 * @type {{AUTHORIZED_OPTION: string, TEMP_DATA: string, EXITED_AT: string, LOCATION_ID: string, DATE_TIME: string, ENTERED_AT: string}}
 */
export const localStorageKey = {
    TEMP_DATA: 'tempData',
    DATE_TIME: 'dateTime',
    LOCATION_ID: 'locationId',
    ENTERED_AT: 'enteredAt',
    EXITED_AT: 'exitedAt',
    AUTHORIZED_OPTION: 'authorized-option'
};

/**
 * Sentry関連の設定値
 * @type {{DSN: string}}
 */
export const sentryConfig = {
    DSN: 'https://703f3834c9be4774a2f7133805ffb53c@o447736.ingest.sentry.io/5433446'
};

/**
 * 履歴更新画面カレンダー用
 * @type {{format: string}}
 */
export const registeredHistoryDetailCalenderFormat = {
    format: '{0}:{1}'
};

export const errorHandling = {
    SUCCESS: 'success',
    TOKEN_ERROR: 'tokenError',
    API_ERROR: 'apiError',
    OTHER_ERROR: 'otherError',
};
