import {serverConstants} from '../env/settings';

/**
 * コンソールを表示させる機能
 * @param payload…表示させる内容
 * @param status…logを出すか、infoを出すか、errorを出すかハンドリング
 */
const consoleMethod = (payload, status) => {
    if(serverConstants.DEBUG_LOG) {
        switch (status) {
            case 'log':
                console.log(payload);
                break;
            case 'info':
                console.info(payload);
                break;
            case 'error':
                console.error(payload);
                break;
            default:
                break;
        }
    }
};

export default consoleMethod;
