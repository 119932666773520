import {takeEvery} from 'redux-saga/effects';
import {
    getRegisteredHistoryAPI,
    registrationPlaceToStayApi,
    updatePlaceToStayApi,
    updateRegisteredHistoryAPI
} from '../actions/apiActions';
import {signUpApi} from './../actions/apiActions';
import {getRegisteredHistory} from './getRegisteredHistory';
import {registrationPlaceToStay} from './registrationPlaceToStay';
import {signUp} from './signUp';
import {updatePlaceToStay} from './updatePlaceToStay';
import {updateRegisteredHistory} from './updateRegisteredHistory';


function* rootSaga() {
    yield takeEvery(registrationPlaceToStayApi, registrationPlaceToStay);
    yield takeEvery(updatePlaceToStayApi, updatePlaceToStay);
    yield takeEvery(signUpApi, signUp);
    yield takeEvery(getRegisteredHistoryAPI, getRegisteredHistory);
    yield takeEvery(updateRegisteredHistoryAPI, updateRegisteredHistory);
}


export default rootSaga;
