import {createAction} from 'redux-actions';


/**
 * PLACE_ACTIONS
 * @type {{PASS_PLACE_CODE: string}}
 */
export const PLACE_ACTIONS = {
    PASS_PLACE_CODE: 'PASS_PLACE_CODE'
};

export const passPlaceCode = createAction(PLACE_ACTIONS.PASS_PLACE_CODE);