import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {formatDateTime} from '../../modules/formatMessage';
import {commonStrings, localStorageKey, pathName} from '../../resources/appConstants';
import styles from '../../styles/style.module.scss';


/**
 * 登録内容表示画面
 * @returns {*}
 * @constructor
 */
const RegisteredDisplay = () => {
    const history = useHistory();
    const [restoredData, setRestoredData] = useState({});
    //履歴ボタン押下
    const historyButton = () => {
        // 履歴一覧画面に遷移
        history.push(pathName.registeredHistory);
    };

    // 初期処理
    const initProcess = () => {
        const tempData = JSON.parse(localStorage.getItem(localStorageKey.TEMP_DATA));
        // localStorageがなければ履歴一覧に遷移
        if (!tempData) {
            history.replace(pathName.registeredHistory);
        } else {
            setRestoredData({
                globalId: tempData.tempName,
                locationId: tempData.locationId,
                enteredAt: tempData.enteredAt,
                exitedAt: tempData.exitedAt
            });
        }
        localStorage.setItem(localStorageKey.TEMP_DATA, JSON.stringify(tempData));
    };

    // 画面読み込み時に初期処理を実施
    useEffect(() => initProcess(), []);

    return (
        <div className={styles.registration_wrap}>
            {console.log(restoredData)}
            <div className={styles.registration_item_wrap}>
                <div className={styles.registration_item}>{commonStrings.NICK_NAME}</div>
                <div className={styles.registration_data}>{restoredData.globalId}</div>
                <div className={styles.registration_item}>{commonStrings.PLACE}</div>
                <div className={styles.registration_data}>{restoredData.locationId}</div>
                <div className={styles.registration_item}>{commonStrings.START_DATETIME}</div>
                <div className={styles.registration_data}>{formatDateTime(restoredData.enteredAt)}</div>
                <div className={styles.registration_item}>{commonStrings.END_DATETIME}</div>
                <div className={styles.registration_data}>{formatDateTime(restoredData.exitedAt)}</div>
            </div>
            <div className={styles.registration_line}/>
            <div className={styles.registration_header}>
                <button className={styles.registration_wide_button}
                        onClick={historyButton}>{commonStrings.HISTORY}</button>
            </div>
        </div>
    );
};

/**
 * connect
 */
export default RegisteredDisplay;
