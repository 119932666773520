import SHA256 from 'crypto-js/sha256';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {signUpApi} from '../../actions/apiActions';
import {localStorageKey, loginStrings, pathName, systemName} from '../../resources/appConstants';
import {messageConstants} from '../../resources/messages';
import styles from '../../styles/style.module.scss';

/**
 * 新規登録
 * @param props
 * @returns {*}
 * @constructor
 */
const SignUp = (props) => {
    const history = useHistory();

    //Stateの設定
    const [password, setPassword] = useState('');
    const [globalId, setGlobalId] = useState('');
    const [nickName, setNickName] = useState('');

    const locationId = localStorage.getItem(localStorageKey.LOCATION_ID);

    //新規登録を行うボタンクリック時
    const handleClickSignUp = () => {
        const form = document.getElementById('form');
        const isValid = form.reportValidity();
        if (isValid) {
            if (globalId !== password) {
                alert(messageConstants.EF0001);
            } else {
                //パスワードをハッシュ化
                const shaPassword = SHA256(loginStrings.FIXED_STRING + password).toString();
                //新規登録
                //bodyに詰める変数名に変換
                props.signUp({name: globalId, password: shaPassword, userName: nickName}, (flag) => {
                    if (flag) {
                        //場所コードがある場合は滞在情報登録画面、ない場合はガイド画面に遷移
                        history.replace(locationId ? pathName.loading : pathName.guide);
                    }
                });
            }
        }
    };

    return (
        <div className={styles.login_wrap}>
            <div className={styles.title}>{systemName}</div>
            <div className={styles.login_body}>
                <form name="user" action="#" id="form" className={styles.login_form_wrap}>
                    <div className={styles.login_form}>
                        <div>{loginStrings.GLOBAL_ID}</div>
                        <input placeholder={loginStrings.ID} type='text' id='globalId' name='globalID'
                               pattern="\d{6,7}|[E]\d{6}" maxLength={7}
                               className={styles.login_form_input} required
                               value={globalId} onChange={(event) => setGlobalId(event.target.value)}/>
                    </div>
                    <div className={styles.login_form}>
                        <div>{loginStrings.NICK_NAME}</div>
                        <input placeholder={loginStrings.NICK_NAME} type='text' id='nickName' name='nickName'
                            // TODO 正規表現
                               pattern="^[a-zA-Z\d]{6,30}$" maxLength={30}
                               className={styles.login_form_input} required
                               value={nickName} onChange={(event) => setNickName(event.target.value)}/>
                    </div>
                    <div className={styles.login_form}>
                        <div>{loginStrings.PASSWORD}</div>
                        {/*アカウント情報保存確認ダイアログ・パスワード漏洩ダイアログ回避のためtype=textに設定*/}
                        <input placeholder={loginStrings.PASSWORD} /*type="password"*/ type="text" id="password"
                               name="password"
                               className={styles.login_form_input_password} autoComplete="OFF"
                               pattern="\d{6,7}|[E]\d{6}" maxLength={7}
                               value={password} onChange={(event) => {
                            setPassword(event.target.value);
                        }}/>
                    </div>
                </form>
                {/*新規登録ボタン*/}
                <div className={styles.login_buttons_wrap}>
                    <button className={styles.login_button} type={'button'}
                            onClick={handleClickSignUp}>
                        {loginStrings.CREATE_ACCOUNT}
                    </button>
                </div>
            </div>
        </div>
    );
};

/**
 * mapStateToProps
 * @param state
 * @returns {{pageMode: *}}
 */
SignUp.propTypes = {
    signUp: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        signUp: (param, callback) =>
            dispatch(signUpApi({param: param, callback: callback}))
    };
};

export default connect(
    '',
    mapDispatchToProps,
)(SignUp);
