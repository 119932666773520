import {REGISTERED_HISTORY_ACTIONS} from '../actions/RegisteredHistory';


/**
 * 初期値
 * @type {Array}
 */
const initialState = {
    history: [],
    selected: {},
};

/**
 * History
 * @param state
 * @param action
 * @returns {Array}
 */
export default function registeredHistory(state = initialState, action) {
    switch (action.type) {
        //履歴の更新
        case REGISTERED_HISTORY_ACTIONS.UPDATE_REGISTERED_HISTORY_LIST:
            state = {
                //中身を展開
                ...state,
                history: action.payload
            };
            return state;
        //選択した履歴の保持
        case REGISTERED_HISTORY_ACTIONS.UPDATE_SELECTED_REGISTERED_HISTORY:
            state = {
                //中身を展開
                ...state,
                selected: action.payload
            };
            return state;
        default:
            return state;
    }
}
