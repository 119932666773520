import {serverConstants} from '../env/settings';

export const apiName = serverConstants.API_NAME;


/**
 * ユーザ新規登録
 * @type {{path: string, apiName: string, body: string}}
 */
export const signUpAPI = {
    apiName: apiName,
    path: '/user',
    init: {
        response: true,
    }
};


/**
 * 履歴一覧画面表示
 * @type {{path: string, init: {response: boolean}, apiName: *, method: string}}
 */
export const getRegisteredHistoryAPI = {
    apiName: apiName,
    path: '/History',
    init: {
        response: true,
    }
};

/**
 * 履歴更新
 * @type {{path: string, init: {response: boolean}, apiName: *, method: string}}
 */
export const updateRegisteredHistoryAPI = {
    apiName: apiName,
    path: '/PlaceToStay',
    init: {
        response: true,
    }
};

/**
 * 情報登録
 * @type {{path: string, apiName: string, method: string}}
 */
export const registrationPlaceToStayAPI = {
    apiName: apiName,
    path: '/PlaceToStay',
    init: {
        response: true,
    }
};


/**
 * 情報更新
 * @type {{path: string, apiName: string, body: string}}
 */
export const updatePlaceToStayAPI = {
    apiName: apiName,
    path: '/PlaceToStay',
    method: 'PUT',
    init: {
        response: true,
    }
};
