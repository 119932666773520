import Auth from '@aws-amplify/auth';

// ビルド時のステージごとの名称
const stages = {
    LOCAL: 'local',
    DEV: 'dev',
    PROD: 'prod',
};

//Cognitoのuser pool情報
const awsConfig = () => {
    switch (process.env.REACT_APP_STAGE.trim()) {
        case stages.LOCAL:
        case stages.DEV: {
            return {
                Auth: {
                    region: process.env.REACT_APP_REGION,
                    userPoolId: process.env.REACT_APP_USER_POOL_ID_DEV,
                    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID_DEV,
                    storage: localStorage
                },
                API: {
                    endpoints: [
                        {
                            name: process.env.REACT_APP_API_NAME_DEV,
                            endpoint: process.env.REACT_APP_ENDPOINT_DEV,
                            region: process.env.REACT_APP_REGION,
                            custom_header:
                                async () => {
                                    let token = '';
                                    let option = '';

                                    try {
                                        token = (await Auth.currentSession()).getIdToken().getJwtToken();
                                        option = localStorage.getItem('authorized-option');
                                    } catch (e) {
                                    }
                                    return {
                                        Authorization: token,
                                        // eslint-disable-next-line no-useless-escape
                                        'authorized-option': option
                                    };
                                }
                        }
                    ]
                }
            };
        }
        case stages.PROD: {
            return {
                Auth: {
                    region: process.env.REACT_APP_REGION,
                    userPoolId: process.env.REACT_APP_USER_POOL_ID_PROD,
                    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID_PROD,
                    storage: localStorage
                },
                API: {
                    endpoints: [
                        {
                            name: process.env.REACT_APP_API_NAME_PROD,
                            endpoint: process.env.REACT_APP_ENDPOINT_PROD,
                            region: process.env.REACT_APP_REGION,
                            custom_header:
                                async () => {
                                    let token = '';
                                    let option = '';

                                    try {
                                        token = (await Auth.currentSession()).getIdToken().getJwtToken();
                                        option = localStorage.getItem('authorized-option');
                                    } catch (e) {
                                    }
                                    return {
                                        Authorization: token,
                                        // eslint-disable-next-line no-useless-escape
                                        'authorized-option': option
                                    };
                                }
                        }
                    ]
                }
            };
        }
        default:
    }
};

const apiName = () => {
    switch (process.env.REACT_APP_STAGE.trim()) {
        case stages.LOCAL:
        case stages.DEV: {
            return process.env.REACT_APP_API_NAME_DEV;
        }
        case stages.PROD: {
            return process.env.REACT_APP_API_NAME_PROD;
        }
        default:
    }
};

// 各種設定値
export const serverConstants = {
    //環境情報
    AWS_CONFIG: awsConfig(),
    API_NAME: apiName(),
    // コンソール出力フラグ（環境変数が開発環境系のものであればtrue）
    DEBUG_LOG: (
        process.env.REACT_APP_STAGE.trim() === stages.LOCAL ||
        process.env.REACT_APP_STAGE.trim() === stages.DEV
    )
};
