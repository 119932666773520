import {createAction} from 'redux-actions';


/**
 *HISTORY_ACTIONS
 * @type {{UPDATE_HISTORY: string}}
 */
export const REGISTERED_HISTORY_ACTIONS = {
    //履歴を記録
    UPDATE_REGISTERED_HISTORY_LIST: 'UPDATE_REGISTERED_HISTORY_LIST',
    //選択した履歴を保持
    UPDATE_SELECTED_REGISTERED_HISTORY: 'UPDATE_SELECTED_REGISTERED_HISTORY',
};

//履歴を更新
export const updateRegisteredHistoryList = createAction(REGISTERED_HISTORY_ACTIONS.UPDATE_REGISTERED_HISTORY_LIST);

//選択した履歴更新
export const updateSelectedRegisteredHistory = createAction(REGISTERED_HISTORY_ACTIONS.UPDATE_SELECTED_REGISTERED_HISTORY);

