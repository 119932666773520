import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    commonStrings,
    errorHandling,
    historyStrings,
    localStorageKey,
    pathName,
    registrationDateTimeFormat
} from '../../resources/appConstants';
import styles from '../../styles/style.module.scss';
import {formatDate, formatMessage, formatTime} from '../../modules/formatMessage';
import {getRegisteredHistoryAPI} from '../../actions/apiActions';
import {useHistory} from 'react-router';
import {updateSelectedRegisteredHistory} from '../../actions/RegisteredHistory';

/**
 * 履歴一覧画面
 * @param props
 * @returns {*}
 * @constructor
 */
const RegisteredHistory = props => {
    const screenHistory = useHistory();

    // 戻るボタン押下時
    const handleClickBack = () => {
        // 前の画面の情報を元に遷移する
        screenHistory.goBack();
    };

    //初回マウント時に実行
    useEffect(() => {
        //履歴取得API呼び出し
        props.getRegisteredHistory({}, (flag) => {
            if (flag === errorHandling.TOKEN_ERROR) {
                screenHistory.push(pathName.login);
            }
        });
    }, []);

    return (
        <div className={styles.history_outline}>
            <div className={styles.history_globalid_title}>
                {commonStrings.NICK_NAME}
            </div>
            <div className={styles.history_globalid}>
                {localStorage.getItem(localStorageKey.AUTHORIZED_OPTION)}
            </div>
            <div className={styles.history_list_main}>
                {props.registeredHistoryList && props.registeredHistoryList.map((item, idx) =>
                    <div key={idx}>
                        {idx > 0 && formatDate(props.registeredHistoryList[idx - 1].enteredAt) === formatDate(item.enteredAt) ? null :
                            <div className={styles.history_date}>{formatDate(item.enteredAt)}</div>}
                        <a className={`${styles.text_decoration_none} ${styles.history_item}`} href={'/#'}
                           onClick={(event) => {
                               event.preventDefault();
                               // 滞在時間更新画面へ遷移する
                               props.updateSelectedRegisteredHistory(item);
                               screenHistory.push(pathName.registeredHistoryDetail);
                           }}>
                            <p className={styles.history_time_place}> {formatMessage(historyStrings.TIME_PLACE_FORMAT, [formatTime(item.enteredAt), formatTime(item.exitedAt), item.locationId])}</p>
                        </a>
                    </div>
                )}
            </div>
            <div className={styles.history_button_wrap}>
                <button type='button' className={styles.history_button}
                        onClick={handleClickBack}>{historyStrings.BACK}</button>
            </div>
        </div>

    );
};

/**
 * propTypes
 * @type {{getRegisteredHistory: Validator<NonNullable<T>> | t, updateSelectedRegisteredHistory: Validator<NonNullable<T>> | t, registeredHistoryList: Validator<NonNullable<T>> | t}}
 */
RegisteredHistory.propTypes = {
    //状態
    registeredHistoryList: PropTypes.array.isRequired,

    //関数
    getRegisteredHistory: PropTypes.func.isRequired,
    updateSelectedRegisteredHistory: PropTypes.func.isRequired,

};

/**
 * mapStateToProps
 * @param state
 * @returns {{registeredHistoryList: *}}
 */
const mapStateToProps = state => {
    return {
        registeredHistoryList: state.registeredHistory.history,
    };
};

/**
 * mapDispatchToProps
 * @param dispatch
 * @returns {{getRegisteredHistory: (function(*=): *), updateSelectedRegisteredHistory: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => {
    return {
        getRegisteredHistory: (param, callback) => dispatch(getRegisteredHistoryAPI({
            param: param,
            callback: callback
        })),
        updateSelectedRegisteredHistory: (item) => dispatch(updateSelectedRegisteredHistory(item))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredHistory);