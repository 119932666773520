import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    commonStrings, errorHandling,
    historyStrings,
    pathName,
    registeredHistoryDetailCalenderFormat
} from '../../resources/appConstants';
import styles from '../../styles/style.module.scss';
import {formatDateTime, formatMessage} from '../../modules/formatMessage';
import {useHistory} from 'react-router';
import {updateRegisteredHistoryAPI} from '../../actions/apiActions';
import {messageConstants} from '../../resources/messages';


/**
 * 滞在情報更新画面
 * @param props
 * @returns {*}
 * @constructor
 */
const RegisteredHistoryDetail = props => {
    const screenHistory = useHistory();
    const propsExitedAt = props.selectedHistory.exitedAt ? props.selectedHistory.exitedAt : '';
    const propsEnteredAt = props.selectedHistory.enteredAt ? props.selectedHistory.enteredAt : '';

    //秒数箇所取得
    const index = propsExitedAt ? propsExitedAt.lastIndexOf(':') : 0;
    //秒数を切り取った状態でdatetime-local用に変換
    const exitedAtDateTimeLocal = propsExitedAt.slice(0, index).replace(' ', 'T').replace(/\//g, '-');
    //終了時間
    const [exitedAt, setExitedAt] = useState(exitedAtDateTimeLocal);

    /**
     *日時変更時
     * @param event
     */
    const handleChangeExpiredDate = (event) => {
        setExitedAt(event.target.value);
    };

    // 戻るを行うボタンクリック時
    const handleClickBack = () => {
        // 前の画面の情報を元に遷移する
        screenHistory.goBack();
    };

    // 保存ボタンクリック時
    const handleClickSave = () => {
        const second = new Date(propsExitedAt ? propsExitedAt : null).getSeconds();
        const newExitedAt = formatMessage(registeredHistoryDetailCalenderFormat.format, [exitedAt, second]);

        // 比較対象がエラーの場合ハンドリングはされないが、最終的にAPIにはじかれるので空文字チェックはしない
        if (new Date(newExitedAt) <= new Date(propsEnteredAt)) {
            alert(messageConstants.EF0004);
            setExitedAt(exitedAtDateTimeLocal);
        } else {
            props.updateRegisteredHistoryAPI({
                enteredAt: propsEnteredAt,
                //APIリクエスト用に変換を戻し、変更前の滞在終了日時の秒を追加
                exitedAt: newExitedAt.replace('T', ' ').replace(/-/g, '/'),
            }, (flag) => {
                switch (flag) {
                    case errorHandling.SUCCESS:
                        screenHistory.goBack();
                        break;
                    case errorHandling.TOKEN_ERROR:
                        screenHistory.push(pathName.login);
                        break;
                    default :
                        break;
                }
            });
        }
    };

    //初回マウント時に実行
    useEffect(() => {
        //IDが取得できていない場合履歴一覧画面に遷移
        if (!props.selectedHistory.globalId) {
            screenHistory.push(pathName.registeredHistory);
        }
    }, []);

    return (
        <div className={styles.history_detail_outline}>
            <div className={styles.history_detail_wrap}>
                <div className={styles.history_detail_title}>
                    {commonStrings.NICK_NAME}
                </div>
                <div className={styles.history_globalid}>
                    {props.selectedHistory.globalId}
                </div>
                <div className={styles.history_detail_title}>
                    {commonStrings.PLACE}
                </div>
                <div className={styles.history_detail_value}>
                    {props.selectedHistory.locationId}
                </div>
                <div className={styles.history_detail_title}>
                    {commonStrings.START_DATETIME}
                </div>
                <div className={styles.history_detail_value}>
                    {formatDateTime(propsEnteredAt)}
                </div>
                <div className={styles.history_detail_title}>
                    {commonStrings.END_DATETIME}
                </div>
                <div className={styles.history_detail_value}>
                    {formatDateTime(propsExitedAt)}
                    <form name="user" action="#" id="form">
                        <input type="datetime-local" id="closedt" name="closedt" step={'any'}
                               min={propsEnteredAt ? exitedAtDateTimeLocal : ''}
                               className={styles.history_input_datetime} value={exitedAt}
                               onChange={handleChangeExpiredDate}/>
                    </form>
                </div>
            </div>
            <div className={styles.history_button_left_wrap}>
                <button type='button' className={styles.history_button}
                        onClick={handleClickBack}>{historyStrings.BACK}</button>
            </div>
            <div className={styles.history_button_right_wrap}>
                <button type='button' className={styles.history_button} disabled={!exitedAt}
                        onClick={handleClickSave}>{historyStrings.SAVE}</button>
            </div>
        </div>
    );
};

/**
 * mapStateToProps
 * @param state
 * @returns {{selectedHistory: *}}
 */
const mapStateToProps = state => {
    return {
        selectedHistory: state.registeredHistory.selected
    };
};

/**
 * mapDispatchToProps
 * @param dispatch
 * @returns {{updateRegisteredHistoryAPI: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => {
    return {
        updateRegisteredHistoryAPI: (item, callback) => dispatch(updateRegisteredHistoryAPI({
            item: item,
            callback: callback
        }))
    };
};


/**
 * propTypes
 * @type {{updateRegisteredHistory: *, selectedHistory: *}}
 */
RegisteredHistoryDetail.propTypes = {
    //状態
    selectedHistory: PropTypes.object.isRequired,

    //関数
    updateRegisteredHistoryAPI: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisteredHistoryDetail);